/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Paris"), React.createElement("p", null, "La Seine sert à délimiter le nord de Paris (rive droite) du sud (rive gauche). Le fleuve traverse le ", React.createElement("a", {
    href: "https://www.booking.com/district/fr/paris/paris-centre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "centre historique"), " de la ville, marqué par deux îles : ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/ile-de-la-cite.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "la Cité"), " et ", React.createElement("a", {
    href: "https://www.booking.com/district/fr/paris/ilesaint-louis.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Saint-Louis"), "."), React.createElement("p", null, "En suivant les rives de la Seine, vous pouvez découvrir la plupart des endroits clés de Paris : ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/notre-dame.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Notre-Dame"), ", ", React.createElement("a", {
    href: "https://www.booking.com/district/fr/paris/saintgermaindespres.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Saint-Germain-des-Prés"), " en face du ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/louvre-museum.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Louvre"), ", puis la ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/place-de-la-concorde1.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Concorde"), ", les ", React.createElement("a", {
    href: "https://www.booking.com/district/fr/paris/avenuedeschampselysees.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Champs-Elysées"), ", ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/arc-de-triomphe.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "l’Arc de Triomphe"), ", etc."), React.createElement("p", null, "Ensuite, prenez des monuments comme points de repère :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Le ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/sacre-coeur.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Sacré-Cœur"), " et ", React.createElement("a", {
    href: "https://www.booking.com/district/fr/paris/montmartre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Montmartre"), " pour le nord."), "\n", React.createElement(_components.li, null, "La ", React.createElement("a", {
    href: "https://www.booking.com/district/fr/paris/montparnasse-2.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Tour Montparnasse"), " pour le sud."), "\n", React.createElement(_components.li, null, "La ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/eiffel-tower.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Tour Eiffel"), " pour l’ouest."), "\n", React.createElement(_components.li, null, "La colonne de la ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/opera-bastille.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Bastille"), " pour l’est."), "\n"), React.createElement("p", null, "On peut enfin jouer sur la proximité avec les gares parisiennes : ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/gare-de-lyon.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gare de Lyon"), ", ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/gare-de-l-est.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gare de l’Est"), ", ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/gare-du-nord.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gare du Nord"), ", ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/gare-montparnasse.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Montparnasse"), ", ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/gare-d-austerlitz.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Austerlitz"), " et ", React.createElement("a", {
    href: "https://www.booking.com/landmark/fr/gare-de-paris-saint-lazare.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Saint-Lazare"), ".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
