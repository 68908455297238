import GATSBY_COMPILED_MDX from "/opt/build/repo/gatsby/src/posts/booking/paris.md";
import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Booking from "../components/booking";
import Footer from "../components/footer";
import ShareButton from "../components/shareButton";
import MapPreview from "../components/mapPreview";
import AccommodationSection from "../components/accommodationSection";
import BookingLink from "../components/bookingLink";
import normalizeCityName from "../utils/normalizeCityName";
import getBookingCitySlug from "../utils/getBookingCitySlug";
import {MDXProvider} from "@mdx-js/react";
export function Head({data}) {
  const {mdx} = data;
  const {frontmatter} = mdx;
  const {title, description, path} = frontmatter;
  return React.createElement(Seo, {
    title: title,
    description: description,
    slug: path,
    image: `/thumbs/se-loger${path}.png`
  });
}
function Template({data, pageContext, children}) {
  const {mdx} = data;
  const {frontmatter} = mdx;
  const {title, city, path, countryCode, lat, long} = frontmatter;
  const {cityData} = pageContext;
  const url = `${process.env.GATSBY_BASEURL}${path}`;
  const normalizedCityName = normalizeCityName(city);
  const filterByCategory = (category, subcategory) => {
    return cityData.features.filter(feature => feature.properties.category === category && feature.properties.subcategory === subcategory);
  };
  const hostels = filterByCategory("Hébergement", "Auberge de jeunesse");
  const hotels = filterByCategory("Hébergement", "Hôtel");
  const bnb = filterByCategory("Hébergement", "Chambre d’hôtes");
  const apartments = filterByCategory("Hébergement", "Appartement");
  const bookingCitySlug = getBookingCitySlug(normalizedCityName);
  return React.createElement(Layout, {
    content: "blog"
  }, React.createElement("article", null, React.createElement("div", {
    className: "blog-post-container"
  }, React.createElement("div", {
    className: "blog-post"
  }, React.createElement("header", null, React.createElement("div", null, React.createElement("h1", null, title))), React.createElement("aside", null, React.createElement(ShareButton, {
    title: title,
    url: url
  })), React.createElement("main", null, React.createElement("section", null, React.createElement("div", {
    className: "no-print"
  }, React.createElement("h2", null, "La carte des hébergements à ", city), React.createElement(Booking, {
    ss: city,
    latitude: lat,
    longitude: long
  }))), React.createElement(MDXProvider, null, children), React.createElement("section", null, React.createElement("h2", null, "Notre sélection d’hébergements à ", city), React.createElement(MapPreview, {
    city: city,
    normalizedCityName: normalizedCityName,
    type: "booking"
  }), React.createElement(AccommodationSection, {
    title: "Les meilleures auberges de jeunesse",
    subcategory: "Auberge de jeunesse",
    accommodations: hostels,
    city: city
  }), React.createElement(AccommodationSection, {
    title: "Les meilleurs hôtels",
    subcategory: "Hôtel",
    accommodations: hotels,
    city: city
  }), React.createElement(BookingLink, {
    url: `https://www.booking.com/city/${countryCode}/${bookingCitySlug}.fr.html?aid=397676&label=MytourofEuropeApp`,
    label: `Voir une sélection d’hôtels à ${city}`
  }), React.createElement(AccommodationSection, {
    title: "Les meilleures chambres d’hôtes B&B",
    subcategory: "Chambre d’hôtes",
    accommodations: bnb,
    city: city
  }), React.createElement(BookingLink, {
    url: `https://www.booking.com/bed-and-breakfast/city/${countryCode}/${bookingCitySlug}.fr.html?aid=397676&label=MytourofEuropeApp`,
    label: `Voir une sélection de chambres d’hôtes à ${city}`
  }), React.createElement(AccommodationSection, {
    title: "Les meilleurs appartements et locations",
    subcategory: "Appartement",
    accommodations: apartments,
    city: city
  }), React.createElement(BookingLink, {
    url: `https://www.booking.com/apartments/city/${countryCode}/${bookingCitySlug}.fr.html?aid=397676&label=MytourofEuropeApp`,
    label: `Voir une sélection d’appartements à ${city}`
  })))))), React.createElement(Footer, {
    city: city
  }));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        title
        city
        description
        lat
        long
        countryCode
      }
    }
  }
`;
